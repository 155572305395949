import { Container, Text, ThemeUIStyleObject } from 'theme-ui';
import ProductOptionSelector from '~/components/Product/OptionSelector';
import ProductAddToCartButton from '~/components/Product/AddToCartButton';
// @ts-ignore
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { useState, useEffect, useRef, Dispatch, SetStateAction, MutableRefObject, FormEvent } from 'react';
import SizeChart from '../SizeChart';
import ProductOutOfStock from '../OutOfStock';
import ShippingNote from '~/components/Generic/ShippingNote';
import { getOptionsBySlug } from '~/hooks/utils/product-helpers';
import { MAX_QUANTITY } from '~/utils/constants';
import formatInches from '~/utils/format-inches';
import type { ProductWithStock, VariantWithStock } from '~/hooks/components/use-product-availability';
import addToCartPixel from '~/scripts/NBCU/addToCartPixel';

type Props = {
  productName: string | null | undefined;
  variants: VariantWithStock[] | null | undefined;
  activeVariant: string | undefined;
  optionValues: Queries.OptionValuesFragmentFragment[];
  onVariantChange: (option: Queries.OptionValuesFragmentFragment) => void;
  forwardSx?: ThemeUIStyleObject;
  product: ProductWithStock | null;
  setProductAvailable: Dispatch<SetStateAction<boolean>>;
  productAvailable: boolean;
  comingSoonNotify: MutableRefObject<Queries.NotificationFragmentFragment | null>;
  OOSNotify: MutableRefObject<Queries.NotificationFragmentFragment | null>;
  isFetchingAvailability: boolean;
  freeShipping?: MutableRefObject<Queries.NotificationFragmentFragment | null>;
};

const ProductAddToCart = ({
  productName,
  variants,
  activeVariant,
  optionValues,
  onVariantChange,
  forwardSx,
  product,
  setProductAvailable,
  productAvailable,
  comingSoonNotify,
  OOSNotify,
  isFetchingAvailability,
  freeShipping,
}: Props) => {
  const translate = useTranslate();
  const addToCartRef = useRef<HTMLFormElement>(null);

  const {
    addToCart,
    cart: { lineItems },
  } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [showSizeChart, setShowSizeChart] = useState(false);

  const selectedVariant = variants?.find((v) => v?.id === activeVariant);
  const isAvailable = selectedVariant?.inStock;
  const li = lineItems?.find((li) => li.variant.sku === selectedVariant?.sku);

  const sizes = getOptionsBySlug('size', optionValues);
  const colors = getOptionsBySlug('color', optionValues);
  const [selectedOptions, setSelectedOptions] = useState<Queries.VariantDetailsFragmentFragment['optionValues']>(
    selectedVariant?.optionValues ?? []
  );

  useEffect(() => {
    setSelectedOptions(selectedVariant?.optionValues ?? []);
  }, [selectedVariant]);

  useEffect(() => {
    setProductAvailable(isAvailable && !selectedVariant?.comingSoon && !selectedVariant?.discontinued ? true : false);
  }, [isAvailable, selectedVariant?.comingSoon, selectedVariant?.discontinued]);

  const renderOutOfStock = !isAvailable || selectedVariant?.comingSoon || selectedVariant?.discontinued;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();

    if (isFetchingAvailability || !isAvailable) return;
    // This controls quantity updates in cart
    const itemAddedToCart = new CustomEvent('itemAddedToCart', {
      bubbles: true,
      detail: { quantity: li?.quantity || 0, id: li?.id },
    });
    addToCartPixel();
    setIsLoading(true);
    setApiError(null);

    try {
      await addToCart({ sku: selectedVariant?.sku ?? '', quantity: 1 });
      addToCartRef?.current?.dispatchEvent(itemAddedToCart);
    } catch (error) {
      setApiError(translate('error.api.default'));
    }

    setIsLoading(false);
  };

  const selectedColor = selectedVariant?.optionValues?.find((ov) => ov?.option_type?.[0]?.slug === 'color');
  const selectedSize = selectedVariant?.optionValues?.find((ov) => ov?.option_type?.[0]?.slug === 'size');

  if (!product) return null;

  return (
    <Container variant="fullwidth">
      <form
        name={`${productName}-add-to-cart`}
        onSubmit={handleSubmit}
        ref={addToCartRef}
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          gap: 'var(--vertical-spacing)',
          ...forwardSx,
        }}
      >
        {optionValues && colors?.length > 0 ? (
          <ProductOptionSelector
            type="color"
            options={colors}
            selectedOptions={selectedOptions}
            handleSelection={(option) => onVariantChange(option)}
            presentation={selectedColor?.presentation}
            isKit={false}
            description={selectedColor?.description}
            productId={`${product.slug}`}
          />
        ) : null}

        {optionValues && sizes?.length > 0 ? (
          <ProductOptionSelector
            type="size"
            options={sizes}
            selectedOptions={selectedOptions}
            handleSelection={(option) => onVariantChange(option)}
            presentation={selectedSize?.presentation}
            setShowSizeChart={setShowSizeChart}
            isKit={false}
            description={`${selectedVariant?.model?.modelName} is ${formatInches(
              selectedVariant?.model?.height
            )} and wears size ${selectedVariant?.model?.wornSize}.`}
            productId={`${product.slug}`}
          />
        ) : null}
        <Container variant="fullwidth">
          {selectedVariant?.optionalShippingNote && (
            <ShippingNote note={selectedVariant.optionalShippingNote} forwardSx={{ mb: 'var(--vertical-spacing)' }} />
          )}
          {selectedVariant?.comingSoon ? (
            <Text
              sx={{
                display: 'block',
                textTransform: 'uppercase',
                fontWeight: 'medium',
                fontSize: '24px',
                color: 'rgba(0,0,0,.3)',
                mb: '1.8rem',
              }}
            >
              {translate('product.coming_soon')}
            </Text>
          ) : (
            <ProductAddToCartButton
              discontinued={selectedVariant?.discontinued}
              outOfStock={!isAvailable}
              comingSoon={selectedVariant?.comingSoon}
              disabled={!productAvailable || li?.quantity === MAX_QUANTITY}
              quantityReached={li?.quantity === MAX_QUANTITY}
              isLoading={isLoading || isFetchingAvailability}
              error={apiError}
              freeShipping={freeShipping?.current}
            />
          )}
        </Container>
      </form>

      {renderOutOfStock && (
        <ProductOutOfStock
          comingSoonNotify={comingSoonNotify}
          OOSNotify={OOSNotify}
          sku={selectedVariant?.sku}
          product={product}
          comingSoon={selectedVariant?.comingSoon}
          outOfStock={!isAvailable}
          discontinued={selectedVariant?.discontinued}
        />
      )}

      <SizeChart isOpen={showSizeChart} onDismiss={() => setShowSizeChart(false)} product={product} />
    </Container>
  );
};

export default ProductAddToCart;
